<template>
  <div style="width: 100%;height: 100%;">
    <div class="login-bg">
      <div class="container">
        <div class="login-box">
          <div class="title">账号登录</div>
          <el-form ref="form" :model="form" autoComplete="on" :rules="rules">
            <el-form-item label="" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请输入用户名" autoComplete="on"></el-input>
            </el-form-item>
            <el-form-item label="" prop="loginPwd">
              <el-input v-model="form.loginPwd" type="password" placeholder="请输入密码" autoComplete="on"
                        @keyup.enter.native="handleLogin"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="login-btn" :loading="loading" @click="handleLogin">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <a class="footer" href="https://beian.miit.gov.cn/">闽ICP备2021016242号-1</a>
  </div>
</template>

<script>
import {getPublicKeyApi, userLoginApi} from '@/api'
import {setToken, setUserInfo} from '@/utils'
import {JSEncrypt} from 'jsencrypt'

export default {
        name: 'login',
        data() {
            return {
                publicKey: undefined,
                form: {
                    mobile: undefined,
                    loginPwd: undefined
                },
                rules: {
                    loginPwd: [{required: true, trigger: 'blur', message: '请输入密码'}],
                    mobile: [{required: true, trigger: 'blur', message: '请输入用户名'}]
                },
                loading: false
            }
        },
        mounted() {
            this.getPublicKey()
        },
        methods: {
            getPublicKey() {
                getPublicKeyApi().then(res => {
                    if (res.data) {
                        this.publicKey = res.data
                    }
                })
            },
            // 加密
            encryptedData(data) {
              if (!this.publicKey) {
                return data
              }
              // 新建JSEncrypt对象
              const encryptor = new JSEncrypt()
              // 设置公钥
              encryptor.setPublicKey(this.publicKey)
              // 加密数据
              return encryptor.encrypt(data)
            },
            handleLogin() {
                this.$refs['form'].validate(valid => {
                    if (valid) {
                        this.loading = true
                        const data = JSON.parse(JSON.stringify(this.form))
                        data['loginPwd'] = this.encryptedData(data['loginPwd'])
                        userLoginApi(data).then(res => {
                            this.loading = false
                            if (res) {
                                setToken(res.token)
                                setUserInfo(res.data)
                                this.$message.success('登录成功!')
                                this.$router.push('/device')
                            }
                        }).catch(() => {
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>
